import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import HomepageHero from "../components/homepage-hero"
/* import LogoImage from "../images/logo/ministry-of-pleasure-logo-white-svg.svg" */

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <div className="hero">
      <div className="hero-logo-wrapper"><img className="hero-logo" src={LogoImage} alt="Ministry of Pleasure logo" /></div>
      <div className="scroll-for-more-outer-wrapper">
          <div className="scroll-for-more-wrapper">
              <a href="#homepage-content"><span id="scroll-msg">Scroll For More</span><div className="scroll-for-more-arrow"><span className="glyphicon" aria-hidden="true"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg></span></div></a>
            </div>
        </div>
    </div> */}
    <HomepageHero />
    <section className="highlight-wrapper highlight-wrapper-dark">
        <div className="highlight highlight-intro">
            <h1>Ministry of Pleasure<sup>&reg;</sup></h1>
            <h2>Coming Soon</h2>
            <p>We're working hard on our new pleasure lifestyle web experience.</p>
            <p>Please check back regularly for updates.</p>
        </div>
    </section>
    <section className="highlight-wrapper highlight-wrapper-light">
        <div className="highlight">
            <h2>Pleasure Products</h2>
            <p>At its core <em>Ministry of Pleasure</em> will provide recommendations to inspire and guide you to shop for the best sex toys and accessories available. Our informative reviews will leave nothing to chance along with user reviews, you can be assured that the sex toys we feature are tried and tested for the ultimate orgasmic experience.</p>
        </div>
    </section>
    <section className="highlight-wrapper highlight-wrapper-dark">
        <div className="highlight">
            <h2>Sex and Relationships</h2>
            <p>Everything you’ve ever wanted to know about sex but were afraid to ask. <em>Ministry of Pleasure</em> will feature expert sex and relationships advice. Tackling real life stories and dilemmas, our &quot;sexperts&quot; will be on hand to offer help and advice for you navigating the complexities of modern relationships.</p>
        </div>
    </section>
    <section className="highlight-wrapper highlight-wrapper-light">
      <div className="highlight">
          <h2>Entertainment</h2>
          <p><em>Ministry of Pleasure</em> will be your one stop source for Adult Entertainment News and Reviews. Covering all genres and tastes we will bring the latest video, audio and written content recommendations to you.</p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
