import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import LogoImage from "../images/logo/ministry-of-pleasure-logo-white-svg.svg"

const HomepageHero = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "hero/ministry-of-pleasure-holding-hero-landscape-3x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="div"
      className="hero"
      fluid={imageData}
    >
      <div className="hero-logo-wrapper"><img className="hero-logo" src={LogoImage} alt="Ministry of Pleasure logo" /></div>
      <div className="scroll-for-more-outer-wrapper">
          <div className="scroll-for-more-wrapper">
              <a href="#homepage-content"><span id="scroll-msg">Scroll For More</span><div className="scroll-for-more-arrow"><span className="glyphicon" aria-hidden="true"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg></span></div></a>
            </div>
        </div>
    </BackgroundImage>
  )

  
}

export default HomepageHero
